import 'jquery';
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

// import bootstrap
import '../autoload/bootstrap.js';

var jQueryBridget = require('jquery-bridget');
var Flickity = require('flickity');
// make Flickity a jQuery plugin, now you can use $().flickity()
jQueryBridget('flickity', Flickity, $);

jQuery(function ($) {
  $.expr[':'].icontains = $.expr.createPseudo(function (text) {
    return function (e) {
      return $(e).text().toUpperCase().indexOf(text.toUpperCase()) >= 0;
    };
  });
  // TAB SELECTING
  // var hash = document.location.hash;
  // if (hash && 0 < $('a[href="' + hash + '"]').length) {
  //   $('a[href="' + hash + '"]')[0].click();
  // }
  var hash = document.location.hash,
    tabLinks = $('nav a[role="tab"]');
  if (tabLinks) {
    $(tabLinks).each(function () {
      $(this).on('click', function () {

        if (history.pushState) {
          history.pushState(null, null, this.hash);
        }
        else {
          location.hash = this.hash;
        }
      });
    })

  }
  if (tabLinks && hash) {
    $(tabLinks).each(function () {
      if (hash == this.hash) {
        $(this).click();
      }
    });
  }

  // FLICKITY TAB FIX

  //    var $carousel = $('.multi-carousel').flickity();

  $('[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    //    console.log(e.target.href);
    //   $carousel.show()
    //    .flickity('resize');
    $('.multi-carousel').flickity('resize');
  })

  // CLOSE PAGE NAVS ON CLICK AND REPLACE ACTIVE TEXT
  $('.navigation-menu .nav-link').on('click', function () {
    if ('dropdown' != $(this).data('toggle')) {
      $('.navigation-menu .navbar-collapse').collapse('hide');
      $(this).closest('.navbar').find('.tab-toggler').text($(this).text());
    }
  });
  // LOAD PAGE WITH ACTIVE TEXT ON TOGGLERS
  $('.tab-toggler').each(function (el) {
    if ($(this).closest('nav').find('.nav-item.active').length > 0) {
      $(this).text($(this).closest('nav').find('.nav-item.active').text());
    }
  });

  $('.left-toggler').each(function (el) {
    if ($(this).closest('nav').find('.nav-item.active').length > 0) {
      $(this).text($(this).closest('nav').find('.nav-item.active').text());
    }
  });
  $('.site-toggler').each(function (el) {
    if ($(this).closest('nav').find('.nav-item.active .nav-link').length > 0) {
      $(this).text($(this).closest('nav').find('.nav-item.active .nav-link').text());
    }
  })
  // McMASTER UPPER LOWER CASE FIX
  var elements = ['h6', '.btn', '.navbar-toggler', '.banner-description', '.modal-title', '.sidebar-title', '.card-link',
  '.card-subtitle', '#site-footer a', '.footer-links a', '.sidebar-event-title', 'section h2', 'section h3'];
  $.each(elements, function (index, value) {
    let $this = '';
    $(value + ":icontains('mcmaster')").each(function () {
      $this = $(this);
      $this.html($this.html().replace(/McMASTER/g, "M<span class='lowercase' style='display:inline;'>c</span>MASTER"));
      $this.html($this.html().replace(/MCMASTER/g, "M<span class='lowercase' style='display:inline;'>c</span>MASTER"));
      $this.html($this.html().replace(/mcmaster/g, "M<span class='lowercase' style='display:inline;'>c</span>Master"));
      $this.html($this.html().replace(/McMaster/g, "M<span class='lowercase' style='display:inline;'>c</span>Master"));
      $this.html($this.html().replace(/Mcmaster/g, "M<span class='lowercase' style='display:inline;'>c</span>Master"));
    });
  });
});
