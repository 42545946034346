import $ from "jquery";
let is_tablet = $(window).width() <= 992;
$(function () {
  // Hide the main navigtaion on Department pages Desktop
  if (!js_obj.is_main_site) {
    $(".navbar.navbar-site").addClass("department-nav");
  }

  // Add the active class to the appropriate department menu item in the global mobile menu
  if (!js_obj.is_main_site) {
    let global_menu_items = $("#menu-main-menu .departments-menu a");
    let prev_menu_item = null;
    global_menu_items.each(function () {
      let href = $(this).attr("href");
      if (prev_menu_item == href) {
        return;
      }
      let curr_url = window.location.href;
      let is_dept_url = false;
      // Remove google analytics param (cross domain tracking)
      href = href.split("?_ga")[0];
      href = href.replace(/\/+$/, "");
      curr_url = curr_url.split("?_ga")[0];
      curr_url = curr_url.replace(/\/+$/, "");

      if (
        curr_url == href ||
        (curr_url.includes(href) && $(this).hasClass("dropdown-toggle"))
      ) {
        $(this).addClass("active");
        is_dept_url = true;
      }

      if (is_dept_url) {
        $("#menu-main-menu .departments-menu > a").addClass("active");
      }
      prev_menu_item = href;
    });
  }

  // add 'give' tab in the main menu
  $(".nav-item").each(function () {
    if ($(this).text() == "Give") $(this).addClass("give-tab");
  });

  //eslint-disable-next-line
  // if (js_obj.is_academic_advising_subsite) {
  //   $('#siteMenu > ul > li').each(function () {
  //     let menu_txt = $(this).find('a').text();
  //     if (menu_txt === 'Academic Advising') {
  //       $(this).addClass('active');
  //     }
  //   });
  // }

  //IE MCM footer fix svg placement issue
  var isIE = /*@cc_on!@*/ false || !!document.documentMode;
  if (isIE) {
    setTimeout(function () {
      document
        .getElementById("radiance")
        .setAttribute("preserveAspectRatio", "xMinYMin meet");
    }, 1000);
  }

  // Toggle Navigation Text
  $(".site-toggler").each(function () {
    $(this).text("BARS");
  });

  $(".site-toggler").on("click", function menuToggleText() {
    var togglerMobile = document.getElementsByClassName("site-toggler")[0];
    if (togglerMobile.innerHTML === "MENU") {
      togglerMobile.innerHTML = " ";
    } else {
      togglerMobile.innerHTML = "BARS";
    }
  });

  // Dropdown Toggle
  $(".dropdown-toggle").on("click", () => {
    if ($(this).next("div").css("display") === "none") {
      $(this).addClass("show-submenu");
      $(this).next("div").addClass("show-submenu");
    } else {
      $(this).removeClass("show-submenu");
      $(this).next("div").removeClass("show-submenu");
    }
  });

  // Dropdown Toggle Level 2
  $("dropdown-item.dropdown-toggle").on("click", () => {
    if ($(this).next("div").css("display") === "none") {
      $(this).closest("div").addClass("show-submenu");
    } else {
      $(this).closest("div").removeClass("show-submenu");
    }
  });

  // going to the link instead open the dropdown menu on desktop
  $("#siteMenu li.nav-item.menu-item-has-children.dropdown").on(
    "click",
    function () {
      if (!is_tablet) {
        var url = $(this).find("a").attr("href");
        window.location.href = url;
      }
    }
  );

  // Navigation Sub-menu closed when mouse click out of the sub-menu
  $(document).on("click", function (event) {
    var $trigger = $(".dropdown");
    if ($trigger !== event.target && !$trigger.has(event.target).length) {
      $(".dropdown-toggle").removeClass("show-submenu");
      $(".dropdown-toggle").next("div").removeClass("show-submenu");
    }
  });
});
