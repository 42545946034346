// (function() {
//   /* Load Javascript Object */
//   var fh = document.createElement('script');
//   fh.type = 'text/javascript';
//   fh.async = true;
//   fh.src = 'https://documents.mcmaster.ca/www/brighterworld/headersfooters.js';
//   var fhs = document.getElementsByTagName('script')[0];

//   /*Insert javascript at top of dom */
//   fhs.parentNode.insertBefore(fh, fhs);

//   /*global footerheader:true*/
//   /*eslint no-undef: 2*/

//   /*loaded successful */
//   fh.addEventListener('load', function(event) {
//     var headFoot = new footerheader();
//     headFoot.setRoboto();
//     headFoot.setTitle({ title: '', titleUrl: '', tagline: '' });
//     headFoot.title =
//       "<h1 class='mcmaster-header__title hp-title'><a href='/'>FACULTY <span> OF SOCIAL SCIENCES</span> </a></h1>";
//     if(!js_obj.is_home && !js_obj.is_story) {
//       headFoot.title =  "<h1 class='mcmaster-header__title'><a href='"+js_obj.main_site_url+"'>FACULTY <span> OF SOCIAL SCIENCES</span> </a></h1> <h2 class='mcmaster-header__subtitle'><a class='subtitle-text' href='"+js_obj.current_post_link+"'>"+js_obj.page_title+"</a></h2>";
//     }
//     if(js_obj.department_title !== null && js_obj.department_title.length) {
//       headFoot.title = "<h1 class='mcmaster-header__title'><a href='"+js_obj.main_site_url+"'>FACULTY OF SOCIAL SCIENCES</a></h1><h2 class='mcmaster-header__subtitle'><a href='"+js_obj.sub_site_url+"' class='subtitle-text'>"+js_obj.department_title+"</a></h2>";
//     }
//     headFoot.setSkip([
//       { url: '#mcmenu', text: 'Skip to McMaster Navigation' },
//       { url: '#siteMenu', text: 'Skip to Site Navigation' },
//       { url: '#main', text: 'Skip to main content' }
//     ]);
//     headFoot.initialize();
//     headFoot.setSearchCustom('');
//   });

//   // Accessibility fixes
//   setTimeout(function () {
//     var mcmenuBtn = document.getElementById('mcmenu')
//     mcmenuBtn.setAttribute('aria-controls', 'mcmaster-nav')

//     var mcmenuSubBtn = document.querySelectorAll('#mcmaster-nav > li > a')
//     for( btn of mcmenuSubBtn )  {
//       if(btn.nextSibling.tagName == 'UL') {
//         let ariaControls = btn.nextSibling.getAttribute('id');
//         btn.setAttribute('aria-controls', ariaControls)
//       }
//     }
//   }, 500)

//   setTimeout(function () {
//     var instagramBtn = document.getElementsByClassName('instagram')[0].children[0];
//     if(instagramBtn) { instagramBtn.setAttribute('aria-label', 'link to instagram'); }
//     var twitterBtn = document.getElementsByClassName('twitter')[0].children[0];
//     if(twitterBtn) { twitterBtn.setAttribute('aria-label', 'link to twitter'); }
//     var facebookBtn = document.getElementsByClassName('facebook')[0].children[0];
//     if(facebookBtn) { facebookBtn.setAttribute('aria-label', 'link to facebook');}
//     var youtubeBtn = document.getElementsByClassName('youtube')[0].children[0];
//     if(youtubeBtn) { youtubeBtn.setAttribute('aria-label', 'link to youtube'); }
//     var linkedinBtn = document.getElementsByClassName('linkedin')[0].children[0];
//     if(linkedinBtn) { linkedinBtn.setAttribute('aria-label', 'link to linkedin'); }
//   }, 1000)
// })();
