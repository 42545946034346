// import external dependencies
import 'jquery';
import 'jquery-ui/ui/widgets/datepicker.js';
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

// import bootstrap
import './autoload/bootstrap.js';

// Header & Footer
import '../scripts/mcm/header_footer';

import 'flickity';
import '../scripts/mcm/flickity-fade';
import '../scripts/mcm/mcm-bw';

import '../scripts/common/header_footer';
import '../scripts/common/common';

import '../scripts/components/form';
import './custom-scripts';

//CSS
import '../scss/global.scss';
