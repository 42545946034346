import $ from 'jquery';
let is_tablet = $(window).width() <= 992;
// JavaScript to be fired on all pages
$(function () {
  // Moving Circle
  var circleMask = document.querySelectorAll('.circle-mask');
  for (var i = 0; i < circleMask.length; i++) {
    circleMask[i].classList.add('is-loaded');
  }

  // People Template Card read more button collapse toggle
  setTimeout(function () {
    $('.people-template .profile-item-wrapper').each(function () {
      if (!is_tablet) {
        if ($(this).outerHeight() > '180') {
          $(this).addClass('collapsed')
          $(this).siblings('.profile-item-btn').removeClass('hide')
        }
      } else {
        if ($(this).outerHeight() > '260') {
          $(this).addClass('collapsed')
          $(this).siblings('.profile-item-btn').removeClass('hide')
        }
      }
    })
  }, 1000);


  // =========== VR_TAB_MENU, VR_TAB_MENU_2, VR_TAB_MENU_GRAD (YELLOW TABS) =========
  // First tab open (desktop)
  if (!is_tablet) {
    $('.vr-tab-menu__each:first-child').addClass('active');
    $('.vr-tab-container .tab-pane:first-child').addClass('show active');
  }

  // VR_TAB_MENU, VR_TAB_MENU_2, VR_TAB_MENU_GRAD content nav
  if ($('.vr-tab-content-nav').length) {
    // mobile : scroll the page
    if (is_tablet) {
      $('.vr-tab-content-nav a').on('click', function () {
        var hash = $(this).attr('href');
        if (hash !== '') {
          var closestSection = $(this).closest('.vr-tab-content').find(hash);
          
          scrollToElement(closestSection, 300)
        }
      })
    } else {
      // desktop : scroll the container
      var $scrollingContainer = $('.vr-tab-content');
      $scrollingContainer.on('click', '.vr-tab-contents a', function () {
        var hash = $(this).attr('href');
        var closestSection = $(this).closest('.vr-tab-content').find(hash);
        var scrollTop = $scrollingContainer.scrollTop();
        var position = closestSection.position();
        if (closestSection.length) {
          $('.vr-tab-content').animate({
            scrollTop: (position.top + scrollTop),
          }, 500);
        }
      });
    }
  }

  // ALL VR_TAB_MENU go to Level 1 (Desktop)
  $('.vr-tab-menu__each').on('click', function () {
    // Directly link to the specific tab of VR_TAB_MENU_2
    if (!is_tablet && !$(this).hasClass('active')) {
      $(this).closest('.vr-tab-wrapper').find('.tab-pane.active.show').removeClass('show active');
    }

    // hide all content level 1,2,3
    $(this).closest('.vr-tab-wrapper').find('.vr-tab-content').removeClass('show-tab');
    // show only level 1
    $(this).closest('.vr-tab-wrapper').find('.tab-pane.active.show .vr-tab-content-level1').addClass('show-tab');

    // go to Level 1 (Mobile)
    if (is_tablet) {
      setTimeout(() => {
        $(this).closest('.vr-tab-wrapper').find('.vr-tab-container').addClass('show');
        $(this).closest('.vr-tab-wrapper').find('.tab-pane.active.show .vr-tab-content-level1').addClass('show-tab');

        // get all Element height of the level1
        getElHeight('level1');
      }, 500)
    }

    scrollToElement($(this).closest('.vr-tab-section'), 1000);
  });

  // ALL VR_TAB_MENU go to Level 2 (Desktop)
  $('.go-to-level2').on('click', function () {
    $(this).closest('.tab-pane.show.active').find('.vr-tab-content-level2').addClass('show-tab');
    if (!is_tablet) {
      $(this).closest('.vr-tab-content-level1').removeClass('show-tab')
      $(this).closest('.vr-tab-content-level1').removeClass('show')
      $(this).closest('.vr-tab-content-level1').addClass('fade')
    }

    scrollToElement($(this).closest('.vr-tab-section'), 1000);

    // go to level 2 (mobile)
    if (is_tablet) {
      setTimeout(() => {
        getElHeight('level2')
      }, 2000);
    }
  });

  // 'go back' to previous tab level in all levels (desktop)
  $('.vr-tab-content__goback').on('click', function () {
    // close the current level tab
    $(this).closest('.vr-tab-content').removeClass('show-tab');
  });

  // 'go back' button on level1 (mobile)
  $('.vr-tab-content-level1 .vr-tab-content__goback').on('click', function () {
    if (is_tablet) {
      $(this).closest('.vr-tab-section').find('.vr-tab-menu__each.active').removeClass('active');
      //close the whole container
      $(this).closest('.vr-tab-container').removeClass('show');
      //close level 1 tab
      $(this).closest('.tab-pane.show.active').removeClass('show active');
      //set the height for the wrapper
      $(this).closest('.vr-tab-wrapper').css('height', 'auto');
    }
  });

  // 'go back' button on level2
  $('.vr-tab-content-level2 .vr-tab-content__goback').on('click', function () {
    if (!is_tablet) {
      $(this).closest('.tab-pane.show.active').find('.vr-tab-content-level1').addClass('show');
      $(this).closest('.tab-pane.show.active').find('.vr-tab-content-level1').removeClass('fade');
    }

    var menuHeight = 0;
    for (var k = 1; k <= $(this).closest('.vr-tab-wrapper').find('.vr-tab-menu').children().length; k++) {
      menuHeight += $(this).closest('.vr-tab-wrapper').find('.vr-tab-menu > :nth-child(' + k + ')').outerHeight(true);
    }

    var allElHeightLevel1 = 100;
    for (var i = 1; i <= $(this).closest('.tab-pane.show.active').find('.vr-tab-content.vr-tab-content-level1.show-tab').children().length; i++) {
      allElHeightLevel1 += $(this).closest('.tab-pane.show.active').find('.vr-tab-content.vr-tab-content-level1.show-tab > :nth-child(' + i + ')').outerHeight(true);
    }

    $(this).closest('.vr-tab-wrapper').css('height', Math.max(menuHeight, allElHeightLevel1));
  })

  // 'go back' button on level3
  $('.vr-tab-content-level3 .vr-tab-content__goback').on('click', function () {
    if (!is_tablet) {
      $(this).closest('.tab-pane.show.active').find('.vr-tab-content-level2').addClass('show-tab');
      $(this).closest('.tab-pane.show.active').find('.vr-tab-content-level2').removeClass('fade');
    }

    var menuHeight = 0;
    for (var k = 1; k <= $(this).closest('.vr-tab-wrapper').find('.vr-tab-menu').children().length; k++) {
      menuHeight += $(this).closest('.vr-tab-wrapper').find('.vr-tab-menu > :nth-child(' + k + ')').outerHeight(true);
    }

    var allElHeightLevel1 = 100;
    for (var i = 1; i <= $(this).closest('.tab-pane.show.active').find('.vr-tab-content.vr-tab-content-level1.show-tab').children().length; i++) {
      allElHeightLevel1 += $(this).closest('.tab-pane.show.active').find('.vr-tab-content.vr-tab-content-level1.show-tab > :nth-child(' + i + ')').outerHeight(true);
    }

    var allElHeightLevel2 = 100;
    for (var j = 1; j <= $(this).closest('.tab-pane.show.active').find('.vr-tab-content.vr-tab-content-level2.show-tab').children().length; j++) {
      allElHeightLevel2 += $(this).closest('.tab-pane.show.active').find('.vr-tab-content.vr-tab-content-level2.show-tab > :nth-child(' + j + ')').outerHeight(true);
    }

    $(this).closest('.vr-tab-wrapper').css('height', Math.max(menuHeight, allElHeightLevel1, allElHeightLevel2));
  })

  $('.icon-printer').on('click', function (e) {
    window.print();
  })
});

// VR tab - get level1,2,3 element height
function getElHeight(level) {
  var allElHeight = 50;
  for (var i = 1; i <= $('.tab-pane.show.active .vr-tab-content.vr-tab-content-' + level + '.show-tab').children().length; i++) {
    allElHeight += $('.tab-pane.show.active .vr-tab-content.vr-tab-content-' + level + '.show-tab > :nth-child(' + i + ')').outerHeight(true);
  }
  if (level == 'level1') {
    var menuHeight = $('.vr-tab-menu').outerHeight(true);
    $('.vr-tab-wrapper').css('height', Math.max(menuHeight, allElHeight));
  }
  if (level == 'level2' || level == 'level3') {
    var wrapperHeight = $('.vr-tab-wrapper').outerHeight(true);
    $('.vr-tab-wrapper').css('height', Math.max(wrapperHeight, allElHeight));
    $('.tab-pane.show.active .vr-tab-content.vr-tab-content-' + level + '.show-tab').css('height', Math.max(wrapperHeight, allElHeight));
  }
}

function scrollToElement(elemTo, timeout) {
  $('html, body').animate({
    scrollTop: elemTo.offset().top,
  }, timeout);  
}

// ===========================================================================


// Yellow Filter Dropdown
// placeholder on mobile
if ($('.select').length && is_tablet) {
  $('.select').each(function () {
    var placeholderMbl = $(this).find('.option.placeholder').text()
    $(this).find('.selectedOption').text(placeholderMbl)
  });
}

$(document).on('click', function (event) {
  var $trigger = $('.select');
  if ($trigger !== event.target && !$trigger.has(event.target).length) {
    $('.select').removeClass('open')
  }
});

$('.select .selectedOption').on('click', function () {
  if (!$(this).closest('.select').hasClass('open')) {
    $('.select').removeClass('open')
    $(this).closest('.select').addClass('open')
  } else {
    $(this).closest('.select').removeClass('open')
  }
});

$('.select .option').on('click', function () {
  if ($(this).closest('.select').hasClass('open')) {
    $(this).siblings().removeClass('selected');
    $(this).addClass('selected')
    var selvalue = $(this).text();
    var valAttr = $(this).attr('value')
    $(this).closest('.select').find('.selectedOption').text(selvalue);
    $(this).closest('.select').find('.selectedOption').val(selvalue);
    $(this).closest('.select').find('.selectedOption').attr('data-value', valAttr ? valAttr : selvalue);
    $(this).closest('.select').removeClass('open');
    $(this).closest('.select').removeClass('invalid');
  }
});

// DatePicker Filter
if ($('#filterDateFrom, #filterDateTo').length) {
  $('.clear-date').on('click', function () {
    let calendar_inputs = $('#filterDateFrom, #filterDateTo');
    calendar_inputs.val(null);
    calendar_inputs.trigger('change');
  });

  $(function () {
    var dateFormat = 'mm/dd/yy',
      from = $('#filterDateFrom')
        .datepicker({
          defaultDate: '+1w',
          changeMonth: true,
          maxDate: 0,
        })
        .on('change', function () {
          to.datepicker('option', 'minDate', getDate(this));
        }),
      to = $('#filterDateTo').datepicker({
        defaultDate: '+1w',
        changeMonth: true,
      })
        .on('change', function () {
          from.datepicker('option', 'maxDate', getDate(this));
        });

    function getDate(element) {
      var date;
      try {
        date = $.datepicker.parseDate(dateFormat, element.value);
      } catch (error) {
        date = null;
      }

      return date;
    }
  });
}

// Go Back Button on coming-soon pages
$('.goBack').on('click', function (e) {
  e.preventDefault();
  if (history.length < 2) {
    window.location = window.js_obj.main_site_url;
  } else {
    window.history.back();
  }
});

// Horizontal Tab height fix
if ($('.hr-tab-arrow-content').length) {
  $('.hr-tab-arrow-content').each(
    function () {
      let childrenElHeight = [];
      for (var i = 0; i < $(this).find('.hr-tab-pane').length; i++) {
        childrenElHeight[i] = $(this).find('.hr-tab-pane:nth-child(' + (i + 1) + ')').outerHeight(true);
      }
      let minHeight = Math.max(...childrenElHeight)
      let padding = parseInt($(this).css('padding-top'), 10) + parseInt($(this).css('padding-bottom'), 10)
      $(this).css('min-height', minHeight + padding);
    })
}

// Stop playing Iframe videos on Modal when clicking outside of content or close button
$('.modal .icon-close, .modal .close-text').on('click', function () {
  var iframe = $('.modal iframe');
  if (iframe.length) {
    iframe.each(function () {
      var src = $(this).attr('src');
      $(this).attr('src', src);
    })
  }
});

$('.modal').on('hidden.bs.modal', function () {
  $('.modal .icon-close').trigger('click')
})
