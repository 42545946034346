// Custom Scripts
import $ from "jquery";
/*eslint no-unused-vars: 0*/
window.incrementShare = function (el) {
  let id = el.getAttribute("data-id");
  let column = el.getAttribute("data-column");
  let source = el.getAttribute("data-source");
  let url = el.getAttribute("data-url");
  let msg = "";
  if (column === "twitter") {
    msg = el.getAttribute("data-msg");
  }
  /*global js_obj:true*/
  /*eslint no-undef: 2*/
  let data = {
    id: id,
    column: column,
    source: source,
    blog_id: js_obj.current_blog_id,
  };
  let shareURL = "";
  switch (column) {
    case "twitter":
      shareURL =
        "https://twitter.com/intent/tweet?text=" +
        encodeURI(msg) +
        "&url=" +
        encodeURI(url) +
        "&original_referer=" +
        encodeURI(url);
      break;
    case "facebook":
      shareURL =
        "https://www.facebook.com/sharer/sharer.php?u=" + encodeURI(url);
      break;
    default:
      shareURL =
        "https://www.linkedin.com/sharing/share-offsite/?url=" + encodeURI(url);
  }
  $.post("/wp-admin/admin-ajax.php?action=increment_share", data)
    .done(function (data) {
      let result = JSON.parse(data);
      if (result["msg"] === "OK") {
        let col = document.getElementById(column + "-" + source + "-" + id);
        if (col) {
          col.textContent = result["row"][column];
        }
        let total = document.getElementById("total-" + source + "-" + id);
        if (total) {
          total.textContent = result["row"]["total"] + " SHARES";
        }
      } else {
        console.warn("Share increment failed: " + result["msg"]);
      }
      window.open(
        shareURL,
        "Share story on " + column,
        "height=500,width=500,modal=yes,alwaysRaised=yes"
      );
    })
    .error(function (xhr, error) {
      console.warn("Share increment failed:");
      console.warn(xhr, error);
    });
};

window.emailShare = function (el) {
  let emailTitle = encodeURI("I'd like to share a link with you");
  window.location.href =
    "mailto:?to=&subject=" +
    emailTitle +
    "&body=" +
    el.getAttribute("data-url");
};

let depMenu = document.getElementById("departmentsNavMenu");
if (depMenu !== null) {
  let menuLinks = depMenu.querySelectorAll(".dept-nav-link");
  let pathName = window.location.pathname;
  // let departmentName = pathName.split('/')[1]; // used for sub directory multisite setting
  let origin = window.location.origin;
  // if the pathname has a trailing forward-slash, remove it
  pathName =
    pathName[pathName.length - 1] === "/" ? pathName.slice(0, -1) : pathName;

  Array.prototype.forEach.call(menuLinks, (menuLink) => {
    let menuHref = menuLink.getAttribute("href");
    // if the menu href has the origin in it, remove it
    if (menuHref.indexOf(origin) !== -1) {
      menuHref = menuHref.replace(origin, "");
    }
    // if the menu href has a trailing forward-slash, remove it
    menuHref =
      menuHref[menuHref.length - 1] === "/" ? menuHref.slice(0, -1) : menuHref;
    // if the pathname and menu href match, add the active class to the menu
    // if(menuHref === pathName || (pathName.includes(menuHref) && menuHref != '/' && menuHref != '')) {  // used for sub directory multisite setting
    if (
      menuHref === pathName ||
      (pathName.includes(menuHref) && menuHref != "/" && menuHref != "")
    ) {
      menuLink.classList.add("active");
    }
  });
}

$(document).ready(function () {
  // main Navigtion Menu and footer links open in the same tab in between main site and sub sites
  var all_sites_domain = [];

  js_obj.all_sites.forEach(function (site) {
    all_sites_domain.push(site.domain);
  });

  $("footer a, #siteMenu a").each(function () {
    if (
      all_sites_domain.indexOf(this.hostname) == -1 ||
      $(this).attr("href").indexOf(".pdf") > 0
    ) {
      $(this).attr("target", "_blank");
    }
  });

  $("#nav-button").on("click", () => {
    if ($("#nav-button").hasClass("open")) {
      $("#nav-button").removeClass("open");
      $("#nav-button-wrap").removeClass("purple-nav-bg");
    } else {
      $("#nav-button").addClass("open");
      $("#nav-button-wrap").addClass("purple-nav-bg");
    }
  });

  $(".navbar-collapse").on("click", "a:not([data-toggle])", null, function () {
    $(".navbar-collapse").collapse("hide");
    $("#nav-button").removeClass("open");
    $("#nav-button-wrap").removeClass("purple-nav-bg");
  });

  // console.log(js_obj.main_site_url)
  // console.log(js_obj.academic_advising_url)
  var main_hostname = new URL(js_obj.main_site_url).hostname;
  // if(js_obj.academic_advising_url.length) {
  // var academic_advising_hostname = (new URL(js_obj.academic_advising_url)).hostname;
  // }

  // all anchor tags except main Navigtion Menu and footer
  $("section a, .breadcrumb-nav a").each(function () {
    if (js_obj.is_main_site) {
      // main and humadvising
      if (
        this.hostname != main_hostname ||
        $(this).attr("href").indexOf(".pdf") > 0
      ) {
        $(this).attr("target", "_blank");
        if (
          $(this).hasClass("btn-primary") ||
          $(this).hasClass("btn-secondary")
        ) {
          $(this).addClass("btn-arrow");
        }
      } else if (
        !$(this).hasClass("btn-info") &&
        !$(this).hasClass("btn-link")
      ) {
        $(this).removeClass("btn-arrow");
      }
    } else {
      //departments
      if (
        this.hostname != document.location.hostname ||
        $(this).attr("href").indexOf(".pdf") > 0
      ) {
        $(this).attr("target", "_blank");
        if (
          $(this).hasClass("btn-primary") ||
          $(this).hasClass("btn-secondary")
        ) {
          $(this).addClass("btn-arrow");
        }
      } else if (
        !$(this).hasClass("btn-info") &&
        !$(this).hasClass("btn-link")
      ) {
        $(this).removeClass("btn-arrow");
      }
    }
  });

  // All the cards are clicked, goes to the button links
  $(".card-clickable").on("click", function (e) {
    e.preventDefault();
    var button = $(this).find("a.btn");
    button.each(function () {
      if (js_obj.is_main_site) {
        // main and humadvising
        if (
          this.hostname != main_hostname ||
          $(this).attr("href").indexOf(".pdf") > 0
        ) {
          window.open($(this).attr("href"), "_blank");
        } else {
          window.location = $(this).attr("href");
        }
      } else {
        //departments
        if (
          this.hostname != document.location.hostname ||
          $(this).attr("href").indexOf(".pdf") > 0
        ) {
          window.open($(this).attr("href"), "_blank");
        } else {
          window.location = $(this).attr("href");
        }
      }
      // if (all_sites_domain.indexOf(this.hostname) == -1 || $(this).attr('href').indexOf('.pdf') > 0) {
      //   window.open($(this).attr('href'), '_blank');
      // }else {
      //   window.location = $(this).attr('href');
      // }
    });
  });
});
